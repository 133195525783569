import React from "react";
import "./Gifts5.css";

const Gifts5 = () => {
  return (
    <>
      <div className="gifts2">
        <h3>Best Advent Calendars of the Season</h3>
        <div>
      <a rel="noopener noreferrer" href="https://amzn.to/4dVoCDI" target="blank">
        <div className="item">
        <img src="/images/fallwreath.jpg" alt="Door wreath with fall colours and feathers." width="150px"/>
        <p>Fall Wreath 24 inch Autumn Door Wreath <br></br> Only $34.99</p>
        </div>
        </a>
        
        <a rel="noopener noreferrer" href="https://amzn.to/4efIHEJ" target="blank">
        <div className="item">
        <img src="/images/welcome.jpg" alt="Fall themed hanging welcome sign with pumpkins." width="150px"/>
        <p>Fall Themed Welcome Sign - variety of colours available<br></br> Starting at only $32.99</p>
        </div>
        </a>

        <a rel="noopener noreferrer" href="https://amzn.to/3ZjIlZs" target="blank">
        <div className="item">
        <img src="/images/fallpillow.jpg" alt="Hello Pumpkin throw pillow with black and orange writing." width="150px"/>
        <p>"Hello Pumpkin" RABUSOFA Throw Pillow Covers - assortment of sizes available<br></br> Starting at only $11.99</p>
        </div>
        </a>

        <a rel="noopener noreferrer" href="https://amzn.to/3XmWjHt" target="blank">
        <div className="item">
        <img src="/images/candle.jpg" alt="Pumpkin Chai labled candle in a glass holder." width="150px"/>
        <p>Chloefu LAN Pumpkin Chai Scented Candle<br></br> Only $25.99</p>
        </div>
        </a>

        <a rel="noopener noreferrer" href="https://amzn.to/3XAZ1ZW" target="blank">
        <div className="item">
        <img src="/images/blockset.jpg" alt="^ characters created by building blocks." width="150px"/>
        <p>6-piece mini building block set. Note: Found on a US site.</p>
        </div>
        </a>
        </div>
      </div>
    </>
  );
};

export default Gifts5;