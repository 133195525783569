import React from "react";
import "./Gifts1.css";

const Gifts1 = () => {
  return (
    <>
      <div className="gifts1">
        <h3>Awesome Deals on Beautiful Fall and Halloween Decor, Treats, and Costumes!</h3>
        <p>New deals updated daily</p>
        <div>
          <h3>Decor</h3>
          <a rel="noopener noreferrer" href="https://amzn.to/4dVoCDI" target="blank">
            <div className="item">
              <img src="/images/fallwreath.jpg" alt="Door wreath with fall colours and feathers." width="150px" />
              <p>Fall Wreath 24 inch Autumn Door Wreath</p>
            </div>
          </a>

          <a rel="noopener noreferrer" href="https://amzn.to/4efIHEJ" target="blank">
          <div className="item">
          <img src="/images/welcome.jpg" alt="Fall themed hanging welcome sign with pumpkins." width="150px" />
              <p>Fall Themed Welcome Sign - variety of colours available</p>
            </div>
          </a>

          <a rel="noopener noreferrer" href="https://amzn.to/3ZjIlZs" target="blank">
          <div className="item">
          <img src="/images/fallpillow.jpg" alt="Hello Pumpkin throw pillow with black and orange writing." width="150px" />
              <p>"Hello Pumpkin" RABUSOFA Throw Pillow Covers - assortment of sizes available</p>
            </div>
          </a>

          <a rel="noopener noreferrer" href="https://amzn.to/3XmWjHt" target="blank">
          <div className="item">
          <img src="/images/candle.jpg" alt="Pumpkin Chai labled candle in a glass holder." width="150px" />
              <p>Chloefu LAN Pumpkin Chai Scented Candle<br></br> Only $25.99</p>
            </div>
          </a>

          <a rel="noopener noreferrer" href="https://amzn.to/3z24ZLj" target="blank">
          <div className="item">
          <img src="/images/votiveholder.jpg" alt="3 ceramic jack-o-lantern tea light holders in pink, light yellow, and white." width="150px" />
              <p>Sunlit Ceramic Pumpkin Jack-O-Lantern Candle Holder Set - 3 tea lights included</p>
            </div>
          </a>
        </div>

        <div>
          <h3>Costumes for Kids</h3>
          <a rel="noopener noreferrer" href="https://amzn.to/4gwOtUa" target="blank">
            <div className="item">
              <img src="/images/purpleWitch.jpg" alt="A child wearing a black and purple witch costume holding a black witch's broom." width="150px" />
              <p>Spooktacular Creations Fairytale Witch Deluxe Costume<br></br>Multiple colours available </p>
            </div>
          </a>

          <a rel="noopener noreferrer" href="https://amzn.to/3XNOcoQ" target="blank">
            <div className="item">
              <img src="/images/octopus.jpg" alt="A child wearing an in inflatible octopus costume." width="150px" />
              <p>Awesome Inflatible Octopus Costume <br></br>Also available in adult sizes</p>
            </div>
          </a>

          <a rel="noopener noreferrer" href="https://amzn.to/4guvrxJ" target="blank">
            <div className="item">
              <img src="/images/doctor.jpg" alt="A child wearing green scrubs and a white doctor'd coat Halloween costume." width="150px" />
              <p>GIFTINBOX Doctor Halloween Costume <br></br>Multiple colours available</p>
            </div>
          </a>

          <a rel="noopener noreferrer" href="https://amzn.to/47uwUAg" target="blank">
            <div className="item">
              <img src="/images/werewolf.jpg" alt="A child dressed as a grey werewolf, wearing a blue plaid shirt." width="150px" />
              <p>Spooktacular Creation Scary Werewolf Costume <br></br>Multiple colors available</p>
            </div>
          </a>

          <a rel="noopener noreferrer" href="https://amzn.to/3XQiTIT" target="blank">
            <div className="item">
              <img src="/images/police.jpg" alt="Child's police uniform Halloween costume with several accessories." width="150px" />
              <p>Joyin Toy Spooktacular Deluxe Police Officer Halloween Costume</p>
            </div>
          </a>

        </div>
        <div>
          <h3>Great Costumes for Teens or Adults</h3>
          <a rel="noopener noreferrer" href="https://amzn.to/47viUGual" target="blank">
            <div className="item">
              <img src="/images/alien.jpg" alt="Man in an inflatable alien costume." width="150px" />
              <p>Rhythmarts Alien Inflatable costume</p>
            </div>
          </a>

          <a rel="noopener noreferrer" href="https://amzn.to/4gyxAIZ" target="blank">
          <div className="item">
              <img src="/images/jellyfish.jpg" alt="A ." width="150px" />
              <p>DIY Lightup Jellyfish Costume Kit. Note: From a US site.</p>
            </div>
          </a>

          <a rel="noopener noreferrer" href="https://amzn.to/3zsfqI7" target="blank">
          <div className="item">
          <img src="/images/bat.jpg" alt="Woman dressing in a black bat costume." width="150px" />
              <p>Spooktacular Creations Black Bat Zip Halloween Costume<br></br>Use coupon for extra savings!</p>
            </div>
          </a>

          <a rel="noopener noreferrer" href="https://amzn.to/47up8pS" target="blank">
          <div className="item">
          <img src="/images/mask.jpg" alt="Person wearing hoodie and LED mask covering their whole face." width="150px" />
              <p>Depointer LED mask with gesture Sensing. Perfect for teens<br></br>Use coupon to get extra savings!</p>
            </div>
          </a>

          <a rel="noopener noreferrer" href="https://amzn.to/4esqnbt" target="blank">
          <div className="item">
          <img src="/images/glow.jpg" alt="Person dressed in a black jumpsuit with a glow in the dark skeleton printed on it." width="150px" />
              <p>Spooktacular Creations Glow in the Dark Skeleton Jumpsuit Costume</p>
            </div>
          </a>

          <a rel="noopener noreferrer" href="https://amzn.to/3zq4nPO" target="blank">
          <div className="item">
          <img src="/images/taco.jpg" alt="Man dressed as a taco." width="150px" />
              <p>Spooktacular Creations Taco Costume Deluxe Set</p>
            </div>
          </a>
        </div>

        <div>
          <h3>Costumes for Kids</h3>
          <a rel="noopener noreferrer" href="https://amzn.to/4gwOtUa" target="blank">
            <div className="item">
              <img src="/images/purpleWitch.jpg" alt="A child wearing a black and purple witch costume holding a black witch's broom." width="150px" />
              <p>Spooktacular Creations Fairytale Witch Deluxe Costume.<br></br>Multiple colours available. </p>
            </div>
          </a>

          <a rel="noopener noreferrer" href="https://amzn.to/3XNOcoQ" target="blank">
            <div className="item">
              <img src="/images/octopus.jpg" alt="A child wearing an in inflatible octopus costume." width="150px" />
              <p>Awesome Inflatible Octopus Costume. <br></br>Also available in adult sizes.</p>
            </div>
          </a>

          <a rel="noopener noreferrer" href="https://amzn.to/4guvrxJ" target="blank">
            <div className="item">
              <img src="/images/doctor.jpg" alt="A child wearing green scrubs and a white doctor'd coat Halloween costume." width="150px" />
              <p>GIFTINBOX Doctor Halloween Costume <br></br>Multiple colours available</p>
            </div>
          </a>

          <a rel="noopener noreferrer" href="https://amzn.to/47uwUAg" target="blank">
            <div className="item">
              <img src="/images/werewolf.jpg" alt="A child dressed as a grey werewolf, wearing a blue plaid shirt." width="150px" />
              <p>Spooktacular Creation Scary Werewolf Costume <br></br>Multiple colors available</p>
            </div>
          </a>

          <a rel="noopener noreferrer" href="https://amzn.to/3XQiTIT" target="blank">
            <div className="item">
              <img src="/images/police.jpg" alt="Child's police uniform Halloween costume with several accessories." width="150px" />
              <p>Joyin Toy Spooktacular Deluxe Police Officer Halloween Costume</p>
            </div>
          </a>

        </div>

        <div>

          <h3>Treats</h3>
          <a rel="noopener noreferrer" href="https://amzn.to/3XhS4wO" target="blank">
            <div className="item">
              <img src="/images/kitkat.jpg" alt="Halloween Kit Kat bars in fun shapes." width="150px" />
              <p>Nestle Kit Kat Scary Friends fun sized bars</p>
            </div>
          </a>

          <a rel="noopener noreferrer" href="https://amzn.to/3XBBoSg" target="blank">
            <div className="item">
              <img src="/images/reeses.jpg" alt="Halloween Reese's peanut butter cups in fun shapes." width="150px" />
              <p>Reese's Peatnut Butter Shapes, fun sized bars</p>
            </div>
          </a>

          <a rel="noopener noreferrer" href="https://amzn.to/3Tj8XG8" target="blank">
            <div className="item">
              <img src="/images/ricecrispies-1.jpg" alt="Halloween themed rice crispy treats." width="150px" />
              <p>Rice Krispies Squares, fun sized mini bars</p>
            </div>
          </a>

          <a rel="noopener noreferrer" href="https://amzn.to/3z1UmIp" target="blank">
            <div className="item">
              <img src="/images/fullbars.jpg" alt="Halloween themed full sized chocolare bars." width="150px" />
              <p>Nestle Halloween theme Full Size Chocolate Bars</p>
            </div>
          </a>

          <a rel="noopener noreferrer" href="https://amzn.to/3TnccMC" target="blank">
            <div className="item">
              <img src="/images/candy.jpg" alt="Full sized Starburst and Skittles." width="150px" />
              <p>Starburst and Skittles, Fun Size Candies</p>
            </div>
          </a>

        </div>
      </div>
    </>
  );
}

export default Gifts1;