import React from "react";
import "./Home.css";

const Home = () => {
  return (
    <>
    <div className="home-container">
      <h3>This Year's Best Gift Ideas</h3>
      <p>Deals updated daily</p>
      <div>
        
        <a rel="noopener noreferrer" href="https://amzn.to/3ZjIlZs" target="blank">
        <div className="item">
        <img src="/images/freezy.jpg" alt="Cookeez Makery Freezy Cakez - Green toy fridge with Cookeez plushie." width="150px"/>
        <p>*HOT toy*<br></br>Cookeez Makery Freezy Cakez. Mix & Decorate Your Plush Best Friend!</p>
        </div>
        </a>

        <a rel="noopener noreferrer" href="https://amzn.to/47Hiltc" target="blank">
        <div className="item">
        <img src="/images/printer.jpg" alt="A white Instax mini smartphone printer." width="150px"/>
        <p>Fujifilm Instax Mini Link 3 Smartphone Printer <br></br>Multiple colours available</p>
        </div>
        </a>
        
        <a rel="noopener noreferrer" href="https://amzn.to/3XAZ1ZW" target="blank">
        <div className="item">
        <img src="/images/blockset.jpg" alt="^ characters created by building blocks." width="150px"/>
        <p>6-piece mini building block set. <br></br>Note: Found on a US site.</p>
        </div>
        </a>

        <a rel="noopener noreferrer" href="https://amzn.to/3ZChvfa" target="blank">
        <div className="item">
        <img src="images/kodak.jpg" alt="Black and grey Kodak camera with photo being printed from side." width="150px"/>
        <p>Kodak Mini Shot 2 Retro | 68-Sheet Bundle <br></br> Many packages and sizes available</p>
        </div>
        </a>

        <a rel="noopener noreferrer" href="https://amzn.to/4egbLwh" target="blank">
        <div className="item">
        <img src="/images/familypjs.jpg" alt="A falimy wearing matching Christmas themed PJs." width="150px"/>
        <p>IFFEI Family Matching Christmas Pajamas<br></br> All sizes(including pets) and styles available</p>
        </div>
        </a>

        <a rel="noopener noreferrer" href="https://amzn.to/3ZEJUS2" target="blank">
        <div className="item">
        <img src="/images/lights.jpg" alt="Spool of LED a light strip." width="150px"/>
        <p>LED light strip. Music sync colour changing<br></br>
        Note: Found on a US site</p>
        </div>
        </a>

        <a rel="noopener noreferrer" href="https://amzn.to/4eDqzVy" target="blank">
        <div className="item">
        <img src="/images/pop.jpg" alt="Lavender Echo Pop round speaker." width="150px"/>
        <p>*Awesome Deal* Amazon Echo Pop smart apeaker with Alexa<br></br>Multiple colours available</p>
        </div>
        </a>

        <a rel="noopener noreferrer" href="https://amzn.to/3XmWjHt" target="blank">
        <div className="item">
        <img src="/images/clue.jpg" alt="Clue board game in a white and blue square box." width="150px"/>
        <p>Clue Board Game Signature Collection, Premium Packaging and Components</p>
        </div>
        </a>
        
        </div>
    </div>
    </>
  );
} 

export default Home;