import React from "react";
import "./Gifts4.css";

const Gifts4 = () => {
  return (
    <>
      <div className="gifts2">
        <h3>The best gifts for pets this season</h3>
        <div>
        <a rel="noopener noreferrer" href="https://www.amazon.ca/Calming-Dog-Cat-Bed-Donut/dp/B08B4T5ZWJ?crid=2W8O8GE8XV28R&dib=eyJ2IjoiMSJ9.vybU24bOYc3Div044rRdj5jrSBjWf7x5XGpx4xck216Ukn6GAXaGnP8fgtdZDFA-ynH_cfonYTRegv9-t0e77HdNJ8wqncRO_5iPtY-vPFVW0IKWvIh6wjBqzncjJ1XC4KZvwsXVgTSBYo8hPab8NE2snnirhWNRh0jw0VmccDUKCV9zzje1ZAECsBd3vJk8lZZU1UXxQ4-3e2HLyO29G_5xqR704dVXKq4ckcF4yiCAnzTE09UntHQLQT_NtwWjyPu_B0fCS5YMmDbQwCGeh9_QzRBQCoSsKoN5uWQScQ4.kNUrAzzeBRttMC3Tic6iVOiHkqfVYSherKWnq92tJy0&dib_tag=se&keywords=pets%2Bbed&qid=1727284599&sprefix=pets%2Bbe%2Caps%2C168&sr=8-6&th=1&linkCode=sl1&tag=giftideasca0b-20&linkId=3bc73bc75717c39d9fe6e46af1e4f4f7&language=en_CA&ref_=as_li_ss_tl" target="blank">
        <div className="item">
        <img src="/images/catbed.jpg" alt="Round, grey, fluffy pet bed with a long haired white cat pying on top." width="150px"/>
        <p>Calming Dog and Cat Bed Donut<br></br> Multiple sizes and colours available</p>
        </div>
        </a>
        
        <a rel="noopener noreferrer" href="https://amzn.to/4gFL7yw" target="blank">
        <div className="item">
        <img src="/images/tree1.jpg" alt="Pink, green, and white flower themed cat tree with 2 cats." width="150px"/>
        <p>MUTTROS Flower Cat Tree<br></br> Multiple colours and sizes available</p>
        </div>
        </a>

        <a rel="noopener noreferrer" href="https://amzn.to/3ZjIlZs" target="blank">
        <div className="item">
        <img src="/images/dogtoys.jpg" alt="1 brown fox and 1 black and grey raccoon dog toy." width="150px"/>
        <p>JOYELF no stuffing squeaky dog toy, Fox and Raccoon<br></br> *Great Price*</p>
        </div>
        </a>

        <a rel="noopener noreferrer" href="https://amzn.to/3MYeL4k" target="blank">
        <div className="item">
        <img src="/images/dogtreats.jpg" alt="Brown and white bag of dog cookies with a dog wearing a red hat and stack of cookies on the front." width="150px"/>
        <p>Caledon Farms Protein Holiday Cookie-Gingerbread & Lamb</p>
        </div>
        </a>

        <a rel="noopener noreferrer" href="https://amzn.to/3XVkvCg" target="blank">
        <div className="item">
        <img src="/images/dogcal.jpg" alt="Advent calendar with a drawing of a dog and holiday scene." width="150px"/>
        <p>The Dogvent Calendar</p>
        </div>
        </a>

        <a rel="noopener noreferrer" href="https://amzn.to/4exdNYo" target="blank">
        <div className="item">
        <img src="/images/catcal.jpg" alt="Advent calendar with a holiday scene including 2 cats wearing Santa hats." width="150px"/>
        <p>Cat Advent Calendar</p>
        </div>
        </a>

        </div>
      </div>
    </>
  );
};

export default Gifts4;
