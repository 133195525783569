import React from "react";
import "./Gifts2.css";

const Gifts2 = () => {
  return (
    <>
      <div className="gifts2">
        <h3>Popular Kid's Gift Ideas</h3>
        <div>
      <a rel="noopener noreferrer" href="https://amzn.to/3N5Q9Xe" target="blank">
        <div className="item">
        <img src="/images/artkit.jpg" alt="Art kit in case with markers, water colours, and other supples." width="150px"/>
        <p>*Great Deal* 150PC Art kit in in case<br></br> Multiple colours available</p>
        </div>
        </a>
        
        <a rel="noopener noreferrer" href="https://amzn.to/3zwNkLT" target="blank">
        <div className="item">
        <img src="/images/egg.jpg" alt="Yellow and white Gudetama egg plushie." width="150px"/>
        <p>GUND Gudetama “Lazy Sitting Pose” Stuffed Animal Plush, 6"</p>
        </div>
        </a>

        <a rel="noopener noreferrer" href="https://amzn.to/3ZjIlZs" target="blank">
        <div className="item">
        <img src="/images/freezy.jpg" alt="Cookeez Makery Freezy Cakez - Green toy fridge with Cookeez plushie." width="150px"/>
        <p>*HOT toy* <br></br>Cookeez Makery Freezy Cakez. Mix & Decorate Your Plush Best Friend!</p>
        </div>
        </a>

        <a rel="noopener noreferrer" href="https://amzn.to/3zFVWzJ" target="blank">
        <div className="item">
        <img src="/images/blueygame.jpg" alt="Monopoly game box with a picture of Bluey and her family on the cover." width="150px"/>
        <p>Monopoly JUNIOR BLUEY - ENGLISH Board Game</p>
        </div>
        </a>

        <a rel="noopener noreferrer" href="https://amzn.to/3XAZ1ZW" target="blank">
        <div className="item">
        <img src="/images/blockset.jpg" alt="^ characters created by building blocks." width="150px"/>
        <p>6-piece mini building block set. Note: Found on a US site.</p>
        </div>
        </a>

        <a rel="noopener noreferrer" href="https://amzn.to/3ZEJUS2" target="blank">
        <div className="item">
        <img src="/images/lights.jpg" alt="Spool of LED a light strip." width="150px"/>
        <p>LED light strip. Music sync colour changing. Awesome price <br></br>
        Note: from a US site</p>
        </div>
        </a>

        </div>
      </div>
    </>
  );
};

export default Gifts2;
